import { GetLinkPdf } from "@/models/OrdemServicoGerenciar";
import ApiService from "./ApiService";

export const listaOs  = async (codConcessionaria,payload) => {
    const { data } = await ApiService.post(`/ordem_servico_v3/${codConcessionaria}` , payload);
    return data;
 };

export const getPacotes  = async (codConcessionaria) => {
    const { data } = await ApiService.get(`/ordem_servico_pacotes/${codConcessionaria}`);
    return data;
 };

export const getOrdenServicos = async (codConcessionaria,codOS) => {
   const { data } = await ApiService.get(`ordem_servico/${codConcessionaria}/${codOS}`);
   return data;
};

export const getLinkPdfPreOs = async (codConcessionaria,payload) => {
   const { data } = await ApiService.post(`ordem_servico_pdf/${codConcessionaria}`,payload);
   return data;
};

export const paginate  = async (link,payload) => {
   const { data } = await ApiService.post(link , payload);
   return data;
};

export const exportXlsx = async (codConcessionaria,payload) => {
   const { data } = await ApiService.postXlsx(`ordem_servico_export_excel/${codConcessionaria}`,payload);
   return data;
};

export const linkPdfOs = async (codConcessionaria,payload ) : Promise<GetLinkPdf> => {
   const { data } = await ApiService.post(`ordem-servico/link-pdf-os/${codConcessionaria}`, payload);
   return data;
};


 export default {listaOs,getPacotes,linkPdfOs}